import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Link, NavLink } from "react-router-dom";
import BookAndBuy_Logo from "../assets/BookAndBuyLogo.jpg";
import { MdEdit } from "react-icons/md";
import { FaAngleRight } from "react-icons/fa6";
import { useSelector } from "react-redux";

function Sidebar({ from, links, onClose }) {
  const [isClosing, setIsClosing] = useState(false);

  const shopData = useSelector((state) => state?.shopsSlice?.shopsData);

  const handleCloseClick = () => {
    setIsClosing(true);
    setTimeout(onClose, 400);
  };

  return (
    <div className="fixed inset-0 z-50 flex justify-start">
      <div
        className={`fixed inset-0 bg-black transition-opacity duration-300 ${
          isClosing ? "opacity-0" : "opacity-50"
        }`}
        onClick={handleCloseClick}
      ></div>
      <div
        className={`relative h-screen w-80 bg-white text-sm md:text-base lg:text-lg transition-transform duration-300 ${
          isClosing ? "animate-sidebarSlideOut" : "animate-sidebarSlideIn"
        }`}
      >
        {/* LOGO */}
        <div className="flex justify-between items-center pr-1">
          <Link to="/">
            <img
              src={BookAndBuy_Logo}
              alt="Logo"
              className="size-11 p-1 rounded-full"
            />
          </Link>
          <AiOutlineClose
            className="text-lg sm:text-2xl font-semibold cursor-pointer"
            onClick={handleCloseClick}
          />
        </div>

        {from === "Manage" && (
          <NavLink
            to="/edit-shop"
            className="flex items-center justify-between m-2 py-1 px-2 rounded border border-[#E0E0E0] cursor-pointer"
          >
            <div>
              <p className="text-[#292929] font-semibold">
                {shopData?.shopName}
              </p>
              <p className="#3d3d3d">{shopData?.phone}</p>
            </div>
            <div className="flex items-center gap-1">
              <MdEdit />
              <p className="text-[#1E90FF] font-semibold">EDIT</p>
            </div>
          </NavLink>
        )}

        {/* LINKS */}
        {
          <div className="flex flex-col mt-2 text-black">
            {links.map((link, idx) => (
              <NavLink
                key={idx}
                className="flex items-center justify-between m-2 py-1 px-2 rounded border border-[#E0E0E0]"
                to={link.to}
              >
                <span className="flex items-center gap-1">
                  {link.icon}
                  {link.title}
                </span>
                <FaAngleRight />
              </NavLink>
            ))}
          </div>
        }
      </div>
    </div>
  );
}

export default Sidebar;
