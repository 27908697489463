import React from "react";
import { IoCalendarOutline } from "react-icons/io5";

const DiscountCard = ({ onClick }) => {
  return (
    <div className="w-96 bg-gradient-to-r from-[#FF7E5F] to-[#FEB47B] rounded-2xl p-6 mt-4 shadow-lg">
      <p className="text-lg font-bold text-white text-center">
        🔥 Book & Pay Now - Save 10%!
      </p>
      <p className="text-2xl font-extrabold text-white text-center my-3">
        Get 10% OFF 💰
      </p>
      <p className="text-base text-white text-center mb-5">
        Book your appointment now and pay instantly to save big on your service!
      </p>
      <button
        onClick={onClick}
        className="bg-white rounded-full py-3 px-6 flex items-center justify-center w-full hover:bg-gray-100 transition-colors"
      >
        <IoCalendarOutline size={20} color="#FF7E5F" />
        <span className="text-lg font-bold text-[#FF7E5F] ml-3">
          Book & Pay Now
        </span>
      </button>
    </div>
  );
};

export default DiscountCard;
