import React from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineErrorOutline } from "react-icons/md";

const GroceryCategory = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/");
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-[#fdf6e3] px-5">
      <div className="flex flex-col items-center justify-center">
        {/* Icon */}
        <MdOutlineErrorOutline size={100} color="#FF9800" />

        {/* Title */}
        <h1 className="text-2xl md:text-3xl font-bold text-gray-800 text-center my-3">
          This Facility Isn’t Available Yet
        </h1>

        {/* Subtitle */}
        <p className="text-base text-gray-500 text-center mx-2 mb-5">
          We’re working hard to bring this feature to you. Stay tuned for
          updates!
        </p>

        {/* Image */}
        <img
          src="https://cdn-icons-png.flaticon.com/512/4681/4681904.png"
          alt="Unavailable feature"
          className="w-48 h-48 my-5 object-contain"
        />

        {/* Button */}
        <button
          onClick={handleRedirect}
          className="bg-[#FF9800] rounded-lg py-3 px-6 shadow-lg hover:bg-orange-600 transition-colors"
        >
          <span className="text-white font-bold text-base">Back to Home</span>
        </button>
      </div>
    </div>
  );
};

export default GroceryCategory;
