import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Home from "./pages/Home";
import ShopDetail from "./pages/ShopDetail";
import ShopList from "./pages/ShopList";
import CreateShop from "./pages/CreateShop";
import CreateShopFeatures from "./pages/CreateShopFeatures";
import CreateShopProducts from "./pages/CreateShopProducts";
// import ShopAdmin from "./pages/ShopAdmin";
import AllOrders from "./pages/AllOrders";
import Allorders from "./components/adminComponents/AllOrders";
import AboutUs from "./pages/AboutUs";
import AllAppointments from "./pages/AllAppointments";
import AppointmentsList from "./components/adminComponents/AppointmentsList";
import Hero from "./components/Hero";
import RequireUser from "./utils/RequireUser";
import AllShops from "./pages/AllShops";

import LoadingBar from "react-top-loading-bar";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import OnlyIfNotLoggedIn from "./utils/OnlyIfNotLoggedIn";
import FAQs from "./pages/FAQs";
import TermsAndConditions from "./pages/TermsAndConditions";
import ShopPayment from "./pages/ShopPayment";
import CancellationAndRefund from "./pages/CancellationaAndRefund";
import GoogleAuthSuccess from "./pages/GoogleAuthSuccess";
import UpdateProfile from "./pages/UpdateProfile";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PaymentPage from "./pages/PaymentPage";
import BarberCategory from "./pages/BarberCategory";
import ShopsList from "./pages/customerPages/ShopsList";
import Shop from "./pages/customerPages/Shop";
import BookAppointment from "./pages/customerPages/BookAppointment";
import MyAppointments from "./pages/customerPages/MyAppointments";
import ShopAdmin from "./pages/clientPages/ShopAdmin";
import Appointments from "./pages/clientPages/Appointments";
import EditShop from "./pages/clientPages/EditShop";
import UpdateShopFeatures from "./pages/clientPages/UpdateShopFeatures";
import ShopFeaturesPrice from "./pages/customerPages/ShopFeaturesPrice";
import ShopRegister from "./pages/customerPages/ShopRegister";
import ShopFeatures from "./pages/customerPages/ShopFeatures";
import GroceryCategory from "./pages/GroceryCategory";

export const TOAST_SUCCESS = "toast_success";
export const TOAST_FAILURE = "toast_failure";

function App() {
  const isLoading = useSelector((state) => state.appConfigReducer.isLoading);
  const toastData = useSelector((state) => state.appConfigReducer.toastData);
  const loadingRef = useRef(null);

  useEffect(() => {
    if (isLoading) {
      loadingRef.current?.continuousStart();
    } else {
      loadingRef.current?.complete();
    }
  }, [isLoading]);

  useEffect(() => {
    switch (toastData.type) {
      case TOAST_SUCCESS:
        toast.success(toastData.message);
        break;
      case TOAST_FAILURE:
        if (Array.isArray(toastData.message)) {
          toastData.message.forEach((e) => toast.error(e));
        } else {
          toast.error(toastData.message);
        }
    }
  }, [toastData]);

  return (
    <>
      <LoadingBar color="#1e3a8a" ref={loadingRef} />
      <div>
        <Toaster />
      </div>
      <Routes>
        <Route element={<Home />}>
          <Route path="/" element={<Hero />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/terms&Conditions" element={<TermsAndConditions />} />
          <Route
            path="/cancellation&Refund"
            element={<CancellationAndRefund />}
          />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/barber-category" element={<BarberCategory />} />
          <Route path="/grocery-category" element={<GroceryCategory />} />
          <Route path="/shops-list" element={<ShopsList />} />
          <Route path="/shop/:id" element={<Shop />} />
          <Route
            path="/book-appointment/:shopId"
            element={<BookAppointment />}
          />
          <Route element={<RequireUser />}>
            <Route path="/edit-shop" element={<EditShop />} />
            <Route
              path="/update-shop-feature"
              element={<UpdateShopFeatures />}
            />
            <Route path="/shop-feature" element={<ShopFeatures />} />
            <Route path="/shop-feature-price" element={<ShopFeaturesPrice />} />
            <Route path="/shop-detail/:id" element={<ShopDetail />} />
            <Route path="/shop-list/:id" element={<ShopList />} />
            {/* <Route path="/create-shop" element={<CreateShop />} /> */}
            <Route path="/register-shop" element={<ShopRegister />} />
            <Route path="/payment" element={<ShopPayment />} />
            <Route
              path="/create-shop-features/:id"
              element={<CreateShopFeatures />}
            />
            <Route
              path="/create-shop-products/:id"
              element={<CreateShopProducts />}
            />
            {/* <Route path="/shop-admin/:id" element={<ShopAdmin />} /> */}
            <Route path="/shop-admin/:id" element={<ShopAdmin />} />
            <Route
              path="/shop-admin/:id/makePayment"
              element={<PaymentPage />}
            />
            <Route path="/shop-admin/:id/allOrders" element={<Allorders />} />
            <Route path="/allOrders" element={<AllOrders />} />
            {/* <Route path="/allAppointments" element={<AllAppointments />} /> */}
            <Route path="/allAppointments" element={<MyAppointments />} />
            <Route path="appointments-list" element={<Appointments />} />
            <Route
              path="/appointmentsList/:id"
              element={<AppointmentsList />}
            />
            <Route path="/allShops/:id" element={<AllShops />} />
            <Route path="/updateProfile" element={<UpdateProfile />} />
          </Route>
        </Route>
        <Route element={<OnlyIfNotLoggedIn />}>
          <Route element={<Home />}>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route
              path="/auth/google/success"
              element={<GoogleAuthSuccess />}
            />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
