import { configureStore } from "@reduxjs/toolkit";
import cartSlice from "./slices/cartSlice";
import appConfigReducer from "./slices/appConfigSlice";
import shopsSlice from "./slices/shopsSlice";
import barberCart from "./slices/barberCart";

export default configureStore({
  reducer: {
    appConfigReducer,
    shopsSlice,
    cartSlice,
    barberCart,
  },
});
